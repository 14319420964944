import Joi from '@hapi/joi'
import i18n from '../components/i18n'
import { CourseBasic, CourseCounts, CourseDistributionParams, CourseMeta, CourseTags, getCourseFragment, getCourseFragmentName } from './course'
import { EngagementSummary, getEngagementSummaryFragment, getEngagementSummaryFragmentName, getUserEngagementSummaryFragment, getUserEngagementSummaryFragmentName, UserEngagementSummary } from './engagement_summary'
import { ExternalUserAttributeValueType, getOrganizationFragment, getOrganizationFragmentName, OrganizationBasic, OrganizationExternalUserAttributes, OrganizationResponseType, OrganizationSettings, OrganizationTags } from './organization'

export type CompanyType = 'test'|'public'|'other'

type SubAddedAt = {
  courseId: string
  addedAt: number
}

export type CompanyBasic = {
  id: string;
  organizationId: string;
  name: string;
  organizationName: string;
  groupName?: string;
  companyType: CompanyType;
  createdAt: Date;
  createdBy?: string;
  forbidManualEdit: boolean;
  forbidManualEditAllowCourseEdit: boolean;
  disabled: boolean;
  courseId: string|undefined;
  usersCount: number;
  subAddedAt?: SubAddedAt[];
}
export type CompanyTags = {
  tags: string[];
}
export type CompanySubscriptions = {
  subscriptions: string[];
  disabledSubscriptions: string[];
}
export enum CompanyPeerInvitationMode {
  open = 'open',
  approval = 'approval',
  closed = 'closed',
}
export type CompanyPeerInvitationModeType = 'open'|'approval'|'closed'
export type CompanySettings = {
  contact: string;
  contactEmail: string;
  certificateSigner?: string;
  greetingsDisabled: boolean;
  membersListEnabled: boolean;
  wallDisabled: boolean;
  weeklyScoresEmailDisabled: boolean;
  policies: string[];
  language?: string;
  peerInvitationMode?: CompanyPeerInvitationModeType;
  employeeIdRequired: boolean;
  subsDistributionParams: CourseDistributionParams[];
  botAiDisabled: boolean;
  botAiLearnerDisabled: boolean;
}
export type CompanyAccessCodes = {
  accessCodes: string[];
  accessCodesWS: string[];
}
export type CompanyCourses = {
  courses: (CourseBasic&CourseTags&CourseMeta&CourseCounts)[];
  disabledCourses: (CourseBasic&CourseTags&CourseMeta&CourseCounts)[];
  gnowbeLearnCourseIds: string[];
}
export type CompanyInvitedUsersCount = {
  invitedUsersCount: number;
}
export type CompanyEngagementSummary = {
  companyId: string;
  engagementSummary: EngagementSummary;
}
export type CompanyOrganizationSettings = {
  organization: OrganizationBasic&OrganizationSettings,
}
export type CompanyOrganizationExternalUserAttributes = {
  organization: OrganizationBasic&OrganizationExternalUserAttributes,
}
export type CompanyOrganizationTags = {
  organization: OrganizationBasic&OrganizationTags,
}

export type ExternalUserAttributeFilterOperator = 'eq'|'ne'|'gt'|'gte'|'lt'|'lte'|'startsWith'|'startsWithCI'|'contains'|'containsCI'
export function externalUserAttributeFilterComparison(type: ExternalUserAttributeValueType) {
  if (type === 'string') {
    return ['eq', 'ne', 'gt', 'gte', 'lt', 'lte', 'startsWith', 'startsWithCI', 'contains', 'containsCI'] as ExternalUserAttributeFilterOperator[]
  }
  return ['eq', 'ne', 'gt', 'gte', 'lt', 'lte'] as ExternalUserAttributeFilterOperator[]
}
export function externalUserAttributeFilterName(op: 'eq'|'ne'|'gt'|'gte'|'lt'|'lte'|'startsWith'|'startsWithCI'|'contains'|'containsCI') {
  switch (op) {
    case 'eq': return 'Equals (=)'
    case 'ne': return 'Not equals (≠)'
    case 'gt': return 'Is greater then (>)'
    case 'gte': return 'Is greater or equal then (≥)'
    case 'lt': return 'Is less then (<)'
    case 'lte': return 'Is less or equal then (≤)'
    case 'startsWith': return 'Starts with'
    case 'startsWithCI': return 'Starts with (case insensitive)'
    case 'contains': return 'Contains'
    case 'containsCI': return 'Contains (case insensitive)'
    default: return ''
  }
}

export type ExternalUserAttributeFilter = {
  type: string, // always filters
  name: string,
  operator: ExternalUserAttributeFilterOperator,
  value: string,
}
export type ExternalUserAttributeFilters = {
  type: string, // always filters
  join: 'and' | 'or',
  filters: (ExternalUserAttributeFilter|ExternalUserAttributeFilters)[],
}
export type CompanyUserManageMode = 'invitations'|'externalUserAttributes'
export type CompanyAssignmentProfile = {
  userManageMode: CompanyUserManageMode,
  externalUserAttributeFilters: ExternalUserAttributeFilters,
  externalUserAttributeLastChangedAt: number,
  externalUserAttributeLastSync: number,
}

export function isCourseDistributedToCmp(courseId: string, cmp: CompanySubscriptions) {
  if (cmp.subscriptions.includes(courseId)) return true
  if (cmp.disabledSubscriptions.includes(courseId)) return true
  return false
}

export function companySyncedWithExternalUsers(
  orgExternalUsersLastChangedAt: number, cmp: CompanyAssignmentProfile,
) {
  return cmp.externalUserAttributeLastSync > Math.max((orgExternalUsersLastChangedAt || 0), (cmp.externalUserAttributeLastChangedAt || 0))
}

export function isTestOrPublicCourseCompany(company: CompanyBasic) {
  return company.companyType === 'test' || company.companyType === 'public'
}

export function isEveryoneCompany(company: CompanyTags) {
  return company.tags?.includes('everyone') || false
}

export function canEditCompanyName(company: CompanyBasic&CompanyTags) {
  if (company.forbidManualEdit) return false
  if (isTestOrPublicCourseCompany(company)) return false
  if (isEveryoneCompany(company)) return false
  return true
}

export function canEditCompanyCourses(company: CompanyBasic, isAdmin: boolean) {
  if (isAdmin) return true
  if (company.disabled) return false
  if (isTestOrPublicCourseCompany(company)) return false
  if (company.forbidManualEdit && !company.forbidManualEditAllowCourseEdit) return false
  return true
}

export function canEditCompanyUsers(
  isAdmin: boolean,
  isSuperEditor: boolean,
  type: 'invitation_link'|'csv_upload'|'individual_invite',
  company: CompanyBasic,
) {
  if (isAdmin) return true
  if (isSuperEditor) return true
  if (company.companyType === 'public') return false
  return true
}

export function canEditCompanyPeerInvitationMode(isAdmin: boolean, company: CompanyBasic) {
  if (isAdmin) return true
  if (isTestOrPublicCourseCompany(company)) return false
  return true
}

export function canMoveCompany(company: CompanyBasic&CompanyTags) {
  if (isTestOrPublicCourseCompany(company)) return false
  if (isEveryoneCompany(company)) return false
  if (company.forbidManualEdit) return false
  return true
}

export function showPendingUsersTab(company: CompanySettings) {
  return company.peerInvitationMode && company.peerInvitationMode !== 'closed'
}

export type CompanyResponseType = 'with_tags'
  |'with_subscriptions'
  |'with_settings'
  |'with_accesscodes'
  |'with_courses'
  |'with_invitedusers_count'
  |'with_organization_settings'
  |'with_organization_external_user_attributes'
  |'with_organization_tags'
  |'with_assignment_profile'

export const getCompanyFragmentName = (types: CompanyResponseType[]) => {
  return `CompanyFields${types.sort().map(t => `_${t}`).join('')}`
}
export const getCompanyFragment = (types: CompanyResponseType[]) => {
  const coursesFragment = types.indexOf('with_courses') >= 0
    ? getCourseFragment(['with_tags', 'with_meta', 'with_counts'])
    : ''

  const organizationFragments = [] as OrganizationResponseType[]
  if (types.indexOf('with_organization_settings') >= 0) {
    organizationFragments.push('with_settings')
  }
  if (types.indexOf('with_organization_external_user_attributes') >= 0) {
    organizationFragments.push('with_externalUserAttributes')
  }
  if (types.indexOf('with_organization_tags') >= 0) {
    organizationFragments.push('with_tags')
  }
  // tslint:disable-next-line: prefer-template
  return `
    ${coursesFragment}
    ${organizationFragments.length > 0
      ? getOrganizationFragment(organizationFragments)
      : ''}
    fragment ${getCompanyFragmentName(types)} on Company {
      id
      organizationId
      name
      organizationName
      groupName
      companyType
      createdAt
      createdBy
      forbidManualEdit
      forbidManualEditAllowCourseEdit
      disabled
      courseId
      subAddedAt {
        courseId
        addedAt
      }
      usersCount` +
      (types.indexOf('with_tags') >= 0 ? `
      tags` : '') +
      (types.indexOf('with_subscriptions') >= 0 ? `
      subscriptions
      disabledSubscriptions` : '') +
      (types.indexOf('with_settings') >= 0 ? `
      contact
      contactEmail
      certificateSigner
      greetingsDisabled
      membersListEnabled
      wallDisabled
      weeklyScoresEmailDisabled
      botAiDisabled
      botAiLearnerDisabled
      policies
      peerInvitationMode
      employeeIdRequired
      language
      subsDistributionParams {
        courseId
        manualReviewsSkipEndorsment
        showHintsAfterAssessmentsCompleted
        hideAssessmentsAfterCompleted
        absoluteDeadline
        relativeDeadline
        futureDistribution
        dueDate
      }` : '') +
      (types.indexOf('with_accesscodes') >= 0 ? `
      accessCodes
      accessCodesWS` : '') +
      (types.indexOf('with_courses') >= 0 ? `
      courses {
        ...${getCourseFragmentName(['with_tags', 'with_meta', 'with_counts'])}
      }
      disabledCourses {
        ...${getCourseFragmentName(['with_tags', 'with_meta', 'with_counts'])}
      }
      gnowbeLearnCourseIds` : '') +
      (types.indexOf('with_invitedusers_count') >= 0 ? `
      invitedUsersCount
      ` : '') +
      (organizationFragments.length > 0 ? `
      organization {
        ...${getOrganizationFragmentName(organizationFragments)}
      }
      ` : '') +
      (types.indexOf('with_assignment_profile') >=  0 ? `
      externalUserAttributeFilters {
        type
        join
        filters
      }
      userManageMode
      externalUserAttributeLastChangedAt
      externalUserAttributeLastSync
      ` : '') + `
    }
  `
}


// OrganizationCompany
export const getOrganizationCompanyFragmentName = () => {
  return 'OrganizationCompanyFields'
}
export const getOrganizationCompanyFragment = () => {
  return `
    ${getCompanyFragment(['with_tags', 'with_subscriptions', 'with_invitedusers_count'])}
    ${getEngagementSummaryFragment()}
    fragment ${getOrganizationCompanyFragmentName()} on OrganizationCompany {
      company {
        ...${getCompanyFragmentName(['with_tags', 'with_subscriptions', 'with_invitedusers_count'])}
      }
      engagementSummary{
        ...${getEngagementSummaryFragmentName()}
      }
    }
  `
}

export type GetUserSubscriptions2Res = {
  companiesRes: {
    companies: CompanyBasic[];
  };
}
export type GetUserSubscriptionsVars = {
  archived: boolean;
  userId: string;
  organizationId?: string;
}

export type CompanyBasicName = {
  id: string;
  organizationId: string;
  name: string;
  organizationName: string;
  groupName?: string;
}
export const getCompanyBasicFragmentName = () => {
  return 'CompanyBasicFields'
}
export const getCompanyBasicFragment = () => {
  return `
    fragment ${getCompanyBasicFragmentName()} on CompanyBasic {
      id
      organizationId
      name
      organizationName
      groupName
    }
  `
}

export type CompanyUpdate = Pick<
  CompanyBasic&CompanyTags&CompanySettings,
  'name'|'groupName'|'contact'|'contactEmail'|'language'|'certificateSigner'|'tags'|'disabled'|'greetingsDisabled'|'weeklyScoresEmailDisabled'|'membersListEnabled'|'wallDisabled'|'peerInvitationMode'|'employeeIdRequired'|'botAiDisabled'|'botAiLearnerDisabled'
>
export const CompanyUpdate = Joi.object().keys({
  name: Joi.string().label('Name').min(3).regex(/[^\.]$|^$/i).required(),
  groupName: Joi.string().max(50).allow('', null),
  contact: Joi.string().label('Contact').allow('', null),
  contactEmail: Joi.string().label('Contact eMail').allow('', null),
  certificateSigner: Joi.string().label('Certificate signer')
    .regex(/^$|^(([^;]+;){2}([^;\n\r]+))+[\n\r]*$/i).allow('', null),
  tags: Joi.array().label('Tags').items(Joi.string()).allow(null),
  disabled: Joi.boolean().label('Disabled').allow(null),
  greetingsDisabled: Joi.boolean().label('Weekly scores disabled').allow(null),
  weeklyScoresEmailDisabled: Joi.boolean().label('Weekly scores disabled').allow(null),
  membersListEnabled: Joi.boolean().label('Members list enabled').allow(null),
  wallDisabled: Joi.boolean().label('Group board and sharing are disabled').allow(null),
  language: Joi.string().label('Language').allow('', null),
  peerInvitationMode: Joi.string().label('Invitation mode').allow('', null),
  employeeIdRequired: Joi.boolean().label('Employee ID required').allow(null),
  botAiDisabled: Joi.boolean().label('ChatBot AI creator disabled').allow(null),
  botAiLearnerDisabled: Joi.boolean().label('ChatBot AI learner disabled').allow(null),
})


// UserOrganizationCompany
export type UserOrganizationCompany = {
  company: CompanyBasic&CompanyTags&CompanySettings,
  engagementSummary: UserEngagementSummary,
}

export type UserOrganizationCompaniesRes = {
  id: string,
  userId: string,
  organizationId: string,
  companies: UserOrganizationCompany[],
}

export const getUserOrganizationCompanyFragmentName = () => {
  return 'UserOrganizationCompanyFields'
}
export const getUserOrganizationCompanyFragment = () => {
  return `
    ${getCompanyFragment([])}
    ${getUserEngagementSummaryFragment()}
    fragment ${getUserOrganizationCompanyFragmentName()} on UserOrganizationCompany {
      company {
        ...${getCompanyFragmentName([])}
      }
      engagementSummary{
        ...${getUserEngagementSummaryFragmentName()}
      }
    }
  `
}


export function getPublicFacingCompanyName(
  company: Pick<CompanyBasic, 'name'|'companyType'>,
) {
  if (company.companyType === 'test') {
    return i18n.t('sandbox_group')
  }
  return company.name
}
